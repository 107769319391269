import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async getDepartments() {
    let result = await axios.get(`${API_ROUTES.geography.department}`);

    return result;
  },

  async getMunicipalitiesByDepartment(uuid) {
    let result = await axios.get(`${API_ROUTES.geography.municipality}/${uuid}`);

    return result;
  },
}